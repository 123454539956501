import globalStore from '@/services/global.state';
import DeviceInfo from 'react-native-device-info';
import uuid from 'react-native-uuid';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {http} from '@utils';
export const sendCode = (userPhone: string) => {
  return http.post('app/sendCode', {userPhone});
};
const getUuid = async (manufacturer: string) => {
  const uuids: any = uuid.v4();
  const getStorage = await AsyncStorage.getItem(manufacturer);
  if (getStorage === null) {
    AsyncStorage.setItem(manufacturer, uuids);
    return uuids;
  } else {
    return getStorage;
  }
};
export const userLogin = async (
  userPhone: string,
  code: string,
  userInviteCode?: string,
  isLogin: boolean = true,
) => {
  const manufacturer = await DeviceInfo.getUserAgent();
  const id = await getUuid(manufacturer);
  const date = {
    userPhone,
    code,
    userInviteCode,
    deviceCode: id,
  };
  if (!isLogin && globalStore.isWeb && !userInviteCode) {
    date.userInviteCode = localStorage.getItem('invitationCode') || undefined;
  }
  return http.post<
    null,
    {
      token: string;
      isNewUser: boolean;
    }
  >('app/userLoginNew', date);
};

export const passwordLogin = async (userPhone: string, password: string) => {
  const manufacturer = await DeviceInfo.getUserAgent();
  const id = await getUuid(manufacturer);
  return http.post<null, string>('app/password/login', {
    userPhone,
    password,
    deviceCode: id,
  });
};

export const updatePassword = (password: string) => {
  return http.post<null, string>('app/user/set/password', {
    password,
  });
};
